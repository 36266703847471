<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="footer" v-show="isShow">
        <div class="baseWidth">

            <div class="footer-content">
                <div class="footerFram__list">
                    <div class="footerFram__listPic">
                    </div>
                </div>
                <div class="footerFram__list">
                    <div class="footerFram__listTitle">地址</div>

                    <div class="footerFram__listAdd">
                        <a href="/us/list">中山市博爱六路 综合展厅城市客厅2号门</a>
                    </div>

                    <div class="footerFram__listTel">
                        <a href="/us/list">400 0960 592</a>
                    </div>

                </div>

                <div class="footerFram__list">
                    <div class="footerFram__listTitle">菜单</div>
                    <div class="footerFram__listText">
                        <div class="listText__list" v-for="(item, index) in routes" :key="index">
                            <a :href="item.path">{{ item.meta.title }}</a>
                        </div>
                    </div>

                </div>
                <div class="footerFram__list">
                    <div class="footerFram__listTitle">公众号</div>
                    <div class="footerFram__listCode"></div>
                </div>

            </div>
            <div class="copyright">
                <!-- <span>科建版权所有</span> -->
            </div>
        </div>
        <div class="footerLink">
            <p>
                <span>微电影小镇 </span>
                <span>共享会员服务</span>
                <span>共享出租屋服务</span>
                <span>共享民宿服务</span>
                <span>共享单车</span>
            </p>
        </div>
        <div class="footerCopyright">
            <span class="company-txt" @click="goPreview('BusinessLicense')">@2017-{{ currentYear }} 广东快车科技股份有限公司版权所有</span>
            <a class="miit-txt" style="margin: 0 3vh;" href="https://beian.miit.gov.cn/">{{ currentValue }}</a>
            <span class="certificate-txt" @click="goPreview('certificate')">网络文化经营许可证： 粤网文｛2023｝ 2285-163号</span>
        </div>
    </div>

</template>

<script>
export default {
    props: ['routes'],
    data() {
        return {
            router: true,
            defaultActive: "/",
            isShow: true,
            currentYear: '',
            currentValue: ''
        };
    },
    // eslint-disable-next-line vue/multi-word-component-names
    name: "myfooter",
    created() {
        const currentYear = new Date().getFullYear();
        this.currentYear = currentYear;
    },
    mounted() {
        const url = window.location.href;
        this.currentValue = this.matchIcpNumber(url);
    },
    methods: {
        goPreview(type){
            window.open(`/imgPreview?image=${encodeURIComponent(type)}`, '_blank');
        },
        matchIcpNumber(url) {
            if (url.includes('itos.cn') || url.includes('www.itos.cn')) {
                return '粤ICP备11046395号-1';
            } else if (url.includes('itkc.cn') || url.includes('www.itkc.cn')) {
                return '粤ICP备11046395号-2';
            } else {
                return '未找到匹配的备案号';
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.footer {
    width: 100%;
    padding: 0 !important;
    // overflow: hidden;
    background-color: #111111;
    height: 593px;

    .baseWidth {
        width: 1150px;
        margin: 0 auto;
    }

    .footer-content {
        height: 300px;

        display: flex;
        padding-top: 52px;
        box-sizing: border-box;

        .footerFram__list {

            flex: 1;
            flex: 1;
            margin-left: 50px;
            text-align: left;

            .footerFram__listTitle {
                height: 40px;
                color: white;
                font-size: 14px;
                border-bottom: 1px solid #282828;
            }

            .footerFram__listAdd {
                margin-top: 15px;
                background-image: url(../assets/icon/add.jpg);
                background-size: auto;
                background-repeat: no-repeat;

                background-position: left center;
                padding-left: 39px;
                box-sizing: border-box;
                color: #7e7e7e;
                font-size: 14px;
            }

            .footerFram__listTel {
                margin-top: 35px;
                background-image: url(../assets/icon/tel.jpg);
                background-size: auto;
                background-position: left center;
                padding-left: 39px;
                box-sizing: border-box;
                color: #7e7e7e;
                font-size: 14px;
            }

            .footerFram__listText {
                margin-top: 15px;
                color: #7e7e7e;
                font-size: 14px;

                .listText__list {
                    margin-top: 15px;
                    color: #7e7e7e;
                }

            }
        }

        .footerFram__list:first-child {
            margin-left: 0;
        }

        .footerFram__listPic {
            height: 64px;
            background-image: url(../assets/img/toplogo.png);
            background-size: 75% auto;
            background-repeat: no-repeat;
            background-position: left center;
        }

    }



    .footerLink {
        margin-top: 75px;
        border-top: 1px solid #282828;
        padding-top: 20px;
        color: #7e7e7e;
        font-size: 14px;

        p {
            display: flex;
            justify-content: center;
        }

        p span {
            color: #7e7e7e;
            font-size: 14px;
            text-decoration: none;
            margin: 0 10px;
        }
    }

    .footerFram__listCode {
        margin-top: 15px;
        height: 128px;
        width: 128px;
        background-image: url(../assets/icon/code.jpg);
        background-size: 100% 100%;
        background-position: left top;
    }

    .footerCopyright {
        margin-top: 85px;
        text-align: center;
        color: #7e7e7e;
        font-size: 14px;
    }

    .miit-txt:hover,
    .company-txt:hover,
    .certificate-txt:hover {
        color: #db4242;
        cursor: pointer;
    }

    .copyright {
        height: 30px;
        // background: #125688;

        span {
            color: #fff;
            line-height: 30px;
        }
    }

    a:hover {
        color: #0075bb;
        text-decoration: underline solid #0075bb;
    }
}
</style>